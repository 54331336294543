import React from 'react';
import Header from '../components/Header/index';
import SEO from '../components/SEO/seo';
import NeedTaxi from '../components/BookingForm/Form-Steps/bookingTime';

const NeedTaxiPage = () => (
  <>
    <Header />
    <SEO title="Need Taxi" />
    <NeedTaxi />
  </>
);

export default NeedTaxiPage;
