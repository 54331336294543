import React, { Component, useState, useEffect, useContext } from 'react'
import { Link, navigate } from 'gatsby'
import './index.css'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { isBrowser } from '../../../utils/general'
import DatePicker from './datepicker'
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardLink,
  CardTitle,
  CardSubtitle
} from 'reactstrap'
import BookingHeader from './bookingHeader'
import RightNowImg from '../../../images/booking-time/rightnow.png'
import RightLaterImg from '../../../images/booking-time/ridelater.png'

import { LanguageContext } from '../../../lang'

const NeedTaxi = () => {
  const {
    translations: { bookingTime }
  } = useContext(LanguageContext)
  const {
    rightNowText,
    ridelateText,
    rightNowSubText,
    rideLaterSubText,
    btnText
  } = bookingTime
  let step2 = isBrowser() ? JSON.parse(localStorage.getItem('step2')) : {}

  const _handleSubmit = values => {
    if(values.pickupDate){
      let strDate= values.pickupDate;
      values.pickupDateForText = strDate.toString()
    }
    console.log("BOOKING TIME VALUES*******",values)
    isBrowser() && localStorage.setItem('step3', JSON.stringify(values))
    navigate('/personal-info')
  }

  const initialValues = {
    pickupDate: '',
    rideType: ''
  }

  const schema = Yup.object().shape({
    rideType: Yup.string().required('Select Ride Type'),
    pickupDate: Yup.string().when('rideType', {
      is: 'scheduled_ride',
      then: Yup.string().required('Date is required')
    })
  })

  const RenderRideType = ({ setFieldValue }) => {
    const [cardBorderColor1, setBoderColor1] = useState('black')
    const [cardBorderColor2, setBoderColor2] = useState('black')

    return (
      <div className='container'>
        <div className='row '>
          <div className='col-md-6'>
            <Card
              outline
              color={cardBorderColor1}
              id='rideNow'
              name='rideType'
              style={{
                width: '270px',
                height: '250px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: ' 0px 15px 16px #00000012',
                borderRadius: '10px',
                opacity: 1,
                marginTop: '10px',
                cursor: 'pointer'
              }}
              onClick={() => {
                setFieldValue('rideType', 'ride_now')
                setBoderColor1('danger')
                setBoderColor2('black')
              }}
            >
              <CardBody></CardBody>
              <img
                style={{
                  width: '91px',
                  height: '99px',
                  opacity: 1,
                  alignSelf: 'center'
                }}
                src={RightNowImg}
                alt='RideNowImage'
              />
              <CardBody>
                <CardText
                  style={{
                    textAlign: 'center',
                    font: 'SemiBold 16px/19px Raleway',
                    letterSpacing: '0',
                    color: ' #ED5523',
                    opacity: 0.87
                  }}
                >
                  {rightNowText}
                </CardText>
                <CardText
                  style={{
                    textAlign: 'center',
                    font: 'SemiBold 18px/21px Raleway',
                    letterSpacing: '0',
                    color: ' #474747',
                    opacity: 0.87
                  }}
                >
                  {rightNowSubText}
                </CardText>
              </CardBody>
            </Card>
          </div>
          <div className='col-md-6 '>
            <Card
              outline
              color={cardBorderColor2}
              id='scheduledRide'
              name='rideType'
              style={{
                width: '270px',
                height: '250px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: ' 0px 15px 16px #00000012',
                borderRadius: '10px',
                opacity: 1,
                marginTop: '10px',
                cursor: 'pointer'
              }}
              onClick={() => {
                setFieldValue('rideType', 'scheduled_ride')
                setBoderColor2('danger')
                setBoderColor1('black')
              }}
            >
              <CardBody></CardBody>
              <img
                style={{
                  width: '216px',
                  height: '95px',
                  opacity: 1,
                  alignSelf: 'center'
                }}
                src={RightLaterImg}
                alt='RightLaterImg'
              />
              <CardBody>
                <CardText
                  style={{
                    textAlign: 'center',
                    font: 'SemiBold 16px/19px Raleway',
                    letterSpacing: '0',
                    color: ' #ED5523',
                    opacity: 0.87
                  }}
                >
                  {ridelateText}
                </CardText>
                <CardText
                  style={{
                    textAlign: 'center',
                    font: 'SemiBold 18px/21px Raleway',
                    letterSpacing: '0',
                    color: ' #474747',
                    opacity: 0.87
                  }}
                >
                  {rideLaterSubText}
                </CardText>
              </CardBody>
            </Card>
          </div>
        </div>
        <ErrorMessage name='rideType'>
          {msg => <p className='text-danger p-1 m-0'>{msg}</p>}
        </ErrorMessage>
      </div>
      // <>
      // 	<div className='custom-control custom-radio custom-control-inline taxi-selection'>
      // 		<input
      // 			type='radio'
      // 			id='rideNow'
      // 			name='rideType'
      // 			className='custom-control-input'
      // 			onChange={() => {
      // 				setFieldValue('rideType', 'ride_now')
      // 			}}
      // 		/>
      // 		<label className='custom-control-label' htmlFor='rideNow'>
      // 			I need a taxi right now
      // 		</label>
      // 	</div>
      // 	<div className='custom-control custom-radio custom-control-inline taxi-selection'>
      // 		<input
      // 			type='radio'
      // 			id='scheduledRide'
      // 			name='rideType'
      // 			className='custom-control-input'
      // 			onChange={() => {
      // 				setFieldValue('rideType', 'scheduled_ride')
      // 			}}
      // 		/>
      // 		<label className='custom-control-label' htmlFor='scheduledRide'>
      // 			I need a taxi later
      // 		</label>
      // 	</div>
      // 	<ErrorMessage name='rideType'>
      // 		{msg => <p className='text-danger p-1 m-0'>{msg}</p>}
      // 	</ErrorMessage>
      // </>
    )
  }

  const RenderRideLater = ({ rideType }) => {
    if (rideType !== 'scheduled_ride') return null

    return (
      <div className='form-row mt-4'>
        <div className='col'>
          <Field
            name='pickupDate'
            component={DatePicker}
            placeholder='Enter Pick Up Date'
          />

          <ErrorMessage name='pickupDate'>
            {msg => <p className='text-danger p-1 m-0'>{msg}</p>}
          </ErrorMessage>
        </div>
      </div>
    )
  }

  return (
    <section id='book-form'>
      <BookingHeader
        price={step2.estimateFair ? step2.estimateFair.netFare : 0}
        data={step2}
      />
      <div className='container'>
        <div className='row d-flex justify-content-center mt-5 mb-3'>
          <div className='col-12 ts-ride-now-wrapper p-5'>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={_handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isValid
              }) => (
                  <Form>
                    {isValid}
                    <RenderRideType setFieldValue={setFieldValue} />
                    <RenderRideLater rideType={values.rideType} />

                    <div className='row d-flex justify-content-center mt-5 mb-3'>
                      <div className='col-12 ts-form-btn-wrapper'>
                        <div className='row'>
                          <div className='col-6 p-0'>
                            <div style={{display: "flex",alignItems: "center"}} onClick={() => {
                                window.history.back()
                              }}>
                              <i className='fa fa-arrow-left ts-form-back-arrow'></i>
                              <span>Return to Select Car</span>
                            </div>
                          </div>
                          <div className='col-6 p-0'>
                            <button
                              type='submit'
                              onClick={handleSubmit}
                              className='ts-form-next-link py-2 text-white border-0 m-0 rounded'
                            >
                              {btnText}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NeedTaxi
